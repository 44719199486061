import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid, {GridSpacing} from '@material-ui/core/Grid';
import {GalleryItem} from './GalleryItem';
import {GalleryItemData} from '../core/models/GalleryItemData';
import withWidth from '@material-ui/core/withWidth';
import {isMobile} from '../core/util/ResponsiveUtil';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

interface Props {
    desktopCols?: number;
    mobileCols?: number;
    spacing?: GridSpacing;
    items: GalleryItemData[];
    width: Breakpoint;
}

const _GallerySlider: React.FC<Props> = ({items, desktopCols, mobileCols, spacing, width}) => {
    const md: any = Math.floor(12 / desktopCols!);
    const xs: any = Math.floor(12 / mobileCols!);
    const mobile = isMobile(width);
    const mappedItems = items.map(item => {
        return (
            <Grid key={item.id} item xs={xs} md={md}>
                <GalleryItem image={item.image} linkTo={item.linkTo} text={item.text} header={item.header} />
                {mobile && <Divider variant="middle" />}
            </Grid>
        );
    });
    return (
        <Grid container spacing={spacing} justify="center">
            {mappedItems}
        </Grid>
    );
};

_GallerySlider.defaultProps = {
    spacing: 1,
    desktopCols: 3,
    mobileCols: 1,
};

export const GallerySlider = withWidth()(_GallerySlider);
