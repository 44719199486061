import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {NewsletterSubscribeForm} from './NewsletterSubscribeForm';
import {useNewsletter} from '../common/hooks/useNewsletter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.secondary.light,
            textAlign: 'center',
            '& h3, p': {
                marginBottom: theme.spacing(1.5),
            },
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        rowText: {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'flex-end',
        },
        header: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface Props {
    title?: string;
    subtitle?: string;
    onSubscribeClicked?: (email: string) => void;
}

export const NewsletterSubscribeSection: React.FC<Props> = ({title, subtitle, onSubscribeClicked}) => {
    const {subscribeToNewsletter} = useNewsletter();
    const classes = useStyles();

    const onSubmit = (email: string) => {
        subscribeToNewsletter(email);
        if (onSubscribeClicked) {
            onSubscribeClicked(email);
        }
    };

    return (
        <Box className={classes.container}>
            <Container maxWidth="lg">
                <Grid container justify="center" alignItems="center" spacing={3}>
                    <Grid item sm={6} className={classes.rowText}>
                        <Grid container>
                            {title && (
                                <Grid item md={12}>
                                    <Typography className={classes.header} variant="h4" component="h3">
                                        {title}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item md={12}>
                                <Typography variant="subtitle1">{subtitle}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} className={classes.row}>
                        <NewsletterSubscribeForm onSubmit={onSubmit} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
