import React from 'react';
import Button from '@material-ui/core/Button';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import * as yup from 'yup';
import useFormal from '@kevinwolf/formal-web';
import {FormalTextField} from './forms/FormalTextField';

const schema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
});

const initialValues = {
    email: '',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: {
            width: 200,
        },
        button: {
            marginTop: 3,
            marginLeft: theme.spacing(1),
        },
    }),
);

interface Props {
    onSubmit: (email: string, values?: any) => void;
}
export const NewsletterSubscribeForm: React.FC<Props> = ({onSubmit}) => {
    const classes = useStyles();

    const handleSubmit = (values: any) => {
        // console.log('handleSubmit:', values);
        if (!onSubmit) return;
        onSubmit(values.email);
    };

    const formal = useFormal(initialValues, {
        schema,
        onSubmit: handleSubmit,
    });

    return (
        <form className={classes.container} {...formal.getFormProps()}>
            <FormalTextField
                className={classes.input}
                variant="outlined"
                label={'email'}
                type={'email'}
                margin="dense"
                fullWidth={false}
                {...(formal.getFieldProps('email') as any)}
            />
            <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                {...(formal.getSubmitButtonProps() as any)}
            >
                Subscribe
            </Button>
        </form>
    );
};
