import {graphql, useStaticQuery} from 'gatsby';

export function useIndexPageStaticData() {
    return useStaticQuery(graphql`
        query IndexQuery {
            site {
                siteMetadata {
                    title
                }
            }
            wpgraphql {
                pages(where: {name: "home"}) {
                    nodes {
                        id
                        slug
                        uri
                        seo {
                            ...WPSEOFragment
                        }
                    }
                }
                products(where: {status: "publish"}) {
                    edges {
                        node {
                            ...ProductInfoFragment
                            ...ProductAttrsFragment
                            ...ProductImageThumbMFragment
                            ...ProductVariationsFragment
                        }
                    }
                }
            }
            imageMainBanner: file(relativePath: {eq: "main-banner3.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            imageCollHydrating: file(relativePath: {eq: "collection-hydrating.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            imageCollLookYoung: file(relativePath: {eq: "collection-look-young.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            imageCollOvernight: file(relativePath: {eq: "collection-overnight-recovery3.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allInstaNode {
                edges {
                    node {
                        ...InstagramPostFragment
                    }
                }
            }
        }
    `);
}
