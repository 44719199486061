import React from 'react';
import Img from 'gatsby-image';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Box from '@material-ui/core/Box';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/Comment';
import {InstagramPost} from '../core/models/InstagramPost';
import classNames from 'classnames';

interface Props {
    posts: InstagramPost[];
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
        },
        gridList: {
            flexWrap: 'nowrap',
            transform: 'translateZ(0)',
        },
        gridListTileAnchor: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'block',
            '&:hover': {
                '& $gridListHover': {
                    opacity: '1',
                },
                '& $gridListIconWrap': {
                    marginTop: 0,
                    opacity: 1,
                },
            },
        },
        gridListHover: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            background: 'rgba(0,0,0,.5)',
            transition: '0.25s ease-in-out;',
        },
        gridListIconWrap: {
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            padding: '0 10px',
            marginTop: '-30px',
            opacity: 0,
            transition: '0.25s ease-in-out;',
        },
        gridListIconDelay: {
            transitionDelay: '0.1s',
        },
        gridListIcon: {
            marginRight: '5px',
        },
    }),
);

export const InstagramFeed: React.FC<Props> = ({posts}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const imagesEL = posts.map(post => {
        const {image} = post;
        if (!image || !image.imageFile) return;
        return (
            <GridListTile key={post.id}>
                <a className={classes.gridListTileAnchor} href={post.url} target="_blank" rel="noopener noreferrer">
                    <Img fluid={image.imageFile.childImageSharp.fluid} alt={image.altText} />
                    <Box component="span" className={classes.gridListHover}>
                        <Box component="span" className={classes.gridListIconWrap}>
                            <FavoriteIcon className={classes.gridListIcon} />
                            {post.likes != null ? post.likes : '0'}
                        </Box>
                        <Box
                            component="span"
                            className={classNames(classes.gridListIconWrap, classes.gridListIconDelay)}
                        >
                            <CommentIcon className={classes.gridListIcon} />
                            {post.comments != null ? post.comments : '0'}
                        </Box>
                    </Box>
                </a>
            </GridListTile>
        );
    });
    return (
        <GridList cellHeight="auto" className={classes.gridList} cols={matches ? 6 : 3}>
            {imagesEL}
        </GridList>
    );
};
