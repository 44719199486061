import React from 'react';
import Img from 'gatsby-image';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ImageData} from '../core/models/ImageData';
import {NavLink} from './NavLink';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            color: '#000',
            textTransform: 'uppercase',
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        text: {
            marginTop: theme.spacing(2),
        },
        header: {
            marginBottom: theme.spacing(2),
        },
    }),
);

interface Props {
    image: ImageData;
    linkTo: string;
    text: string;
    header?: string;
}

export const GalleryItem: React.FC<Props> = props => {
    const {image, linkTo, text, header} = props;
    const classes = useStyles();

    const headerEl = header ? (
        <Typography className={classes.header} variant="body1">
            {header}
        </Typography>
    ) : null;

    const imageEl =
        image && image.imageFile ? (
            <Img
                fixed={image.imageFile.childImageSharp.fixed}
                fluid={image.imageFile.childImageSharp.fluid}
                alt={image.altText}
            />
        ) : null;

    return (
        <NavLink to={linkTo}>
            <Box className={classes.container}>
                {headerEl}
                {imageEl}
                <Typography className={classes.text} variant="body1">
                    {text}
                </Typography>
            </Box>
        </NavLink>
    );
};
