import React from 'react';
import Img from 'gatsby-image';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ImageData} from '../core/models/ImageData';
import {NavLink} from './NavLink';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
        },
        media: {
            flex: 1,
            // display: 'flex',
            // alignItems: 'flex-end',
            // alignSelf: 'flex-end',
            // Add media query object-position: -200px
        },
        content: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(2),
        },
        contentWrapper: {
            textAlign: 'center',
            '& p, h3, a': {
                marginBottom: theme.spacing(2),
            },
        },
    }),
);

interface Props {
    title: string;
    text: string;
    callToAction: string;
    onCallToActionClicked?: () => void;
    linkTo: string;
    media: ImageData;
    mediaHeight?: number;
    floatLeft?: boolean;
    style?: any;
}

export const FeaturedItem: React.FC<Props> = props => {
    const {callToAction, floatLeft, linkTo, media, onCallToActionClicked, style, title, text} = props;
    const classes = useStyles();
    // const mediaStyles = {
    //     height: mediaHeight,
    // };
    const mediaEl =
        media && media.imageFile ? (
            <Grid item xs={12} md={4}>
                <Box className={classes.media}>
                    <Img
                        fixed={media.imageFile.childImageSharp.fixed}
                        fluid={media.imageFile.childImageSharp.fluid}
                        alt={media.altText}
                    />
                </Box>
            </Grid>
        ) : null;
    const content = (
        <Grid item xs={12} md={8} className={classes.content}>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item md={8} className={classes.contentWrapper}>
                    <Typography variant="h4" component="h3">
                        {title}
                    </Typography>
                    <Typography variant="body1">{text}</Typography>
                    <NavLink to={linkTo}>
                        <Button variant="outlined" color="inherit" onClick={onCallToActionClicked}>
                            {callToAction}
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
        </Grid>
    );
    const first = floatLeft ? content : mediaEl;
    const second = floatLeft ? mediaEl : content;
    return (
        <Grid container className={classes.container} style={style}>
            {first}
            {second}
        </Grid>
    );
};

FeaturedItem.defaultProps = {
    floatLeft: true,
    mediaHeight: 500,
};
