import addToMailchimp from 'gatsby-plugin-mailchimp';
import {useSnackbar} from 'notistack';

export function useNewsletter() {
    const {enqueueSnackbar} = useSnackbar();

    const subscribeToNewsletter = async (email: string, data?: any) => {
        try {
            const res = await addToMailchimp(email, data);
            if (res.result === 'error') {
                enqueueSnackbar(`There has been an error: ${res.msg}. Please try again!`);
                return;
            }
            enqueueSnackbar('Thanks for Subscribing! 💕💕. Check your email for the discount');
            return;
        } catch (err) {
            console.error('Error subscribing newsletter:', err);
        }
    };
    return {subscribeToNewsletter};
}
