import {ImageData} from '../models/ImageData';
import _words from 'lodash/words';
import {InstagramPost} from '../models/InstagramPost';

function computeInstagramPostUrl(postId: string) {
    return `http://www.instagram.com/p/${postId}`;
}

function getCaptionSummary(caption: string) {
    if (!caption) return;
    const words = _words(caption);
    return words && words.length > 5 ? words.slice(0, 5).join(' ') : caption;
}

export function mapInstagramNodeToInstagramPost(node: any): InstagramPost {
    const captionSummary = node.caption ? getCaptionSummary(node.caption) : '';
    const altText = `Instagram - ${captionSummary}`;
    const image: ImageData = {
        altText,
        imageFile: node.localFile,
        src: node.preview,
    };
    return {
        id: node.id,
        image,
        comments: node.comments,
        url: computeInstagramPostUrl(node.id),
        likes: node.likes,
    };
}

export function mapInstagramNodeListToInstagramPost(edges: any[]): InstagramPost[] {
    return edges.map((edge: any) => mapInstagramNodeToInstagramPost(edge.node));
}
