import React from 'react';
import Box from '@material-ui/core/Box';
import {SectionTitle} from './SectionTitle';
import {GallerySlider} from './GallerySlider';
import {GalleryItemData} from '../core/models/GalleryItemData';
import Container from '@material-ui/core/Container';

interface Props {
    title: string;
    items: GalleryItemData[];
}

export const SectionGallerySlider: React.FC<Props> = props => {
    const {title, items} = props;
    return (
        <Box>
            <SectionTitle title={title} />
            <Container maxWidth="md">
                <GallerySlider desktopCols={3} mobileCols={1} items={items} />
            </Container>
        </Box>
    );
};
