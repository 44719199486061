import React from 'react';
import get from 'lodash/get';
import {ProductGrid} from '../modules/product/containers/ProductGrid';
import SEO from '../modules/layout/containers/SEO';
import {Layout} from '../modules/layout/containers/Layout';
import {SectionTitle} from '../modules/uxui/SectionTitle';
import {SectionProductSlider} from '../modules/product/containers/SectionProductSlider';
import {SectionGallerySlider} from '../modules/uxui/SectionGallerySlider';
import {GalleryItemData} from '../modules/core/models/GalleryItemData';
import {NewsletterSubscribeSection} from '../modules/uxui/NewsletterSubscribeSection';
import {FeaturedItem} from '../modules/uxui/FeaturedItem';
import {mapEdgeNodeListToProduct} from '../modules/core/mapper/ProductMapper';
import {mapLocalSharpImageToImageData} from '../modules/core/util/ImageUtil';
import {Colors} from '../theme';
import {sortDealsProducts, sortFeaturedProducts} from '../modules/core/util/ProductUtil';
import {mapInstagramNodeListToInstagramPost} from '../modules/core/mapper/InstagramMapper';
import {InstagramFeed} from '../modules/uxui/InstagramFeed';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';
import {useIndexPageStaticData} from '../modules/common/hooks/useIndexPageStaticData';

interface Props {
    location: Location;
}

const StoreIndex: React.FC<Props> = ({location}) => {
    const data = useIndexPageStaticData();
    const pagesNodes: any = get(data, 'wpgraphql.pages.nodes');
    const pageData = pagesNodes && pagesNodes.length > 0 ? pagesNodes[0] : null;

    // Products
    const productNodes = get(data, 'wpgraphql.products.edges');
    const products = mapEdgeNodeListToProduct(productNodes);
    const dealsProducts = sortDealsProducts(products).slice(0, 4);
    const topSellers = sortFeaturedProducts(products).slice(0, 8);

    // Featured collections.
    const imageCollHydrating = get(data, 'imageCollHydrating');
    const imageCollLookYoung = get(data, 'imageCollLookYoung');
    const imageCollOvernight = get(data, 'imageCollOvernight');
    const featureCollections: GalleryItemData[] = [
        {
            id: 'col1',
            header: 'Feel Hydrated',
            text: 'Hydrating',
            linkTo: '/collections/anti-aging-cream-serum',
            image: mapLocalSharpImageToImageData(imageCollHydrating, 'Hydrating'),
        },
        {
            id: 'col2',
            header: 'Look Young',
            text: 'Anti-Aging',
            linkTo: '/collections/moisturizers',
            image: mapLocalSharpImageToImageData(imageCollLookYoung, 'Anti-Aging'),
        },
        {
            id: 'col3',
            header: 'Restore Overnight',
            text: 'Night Cream',
            linkTo: '/collections/night-cream-serum',
            image: mapLocalSharpImageToImageData(imageCollOvernight, 'Night Cream'),
        },
    ];
    const imageMainBanner = mapLocalSharpImageToImageData(get(data, 'imageMainBanner'), 'CBD SkinCare');
    const onSubscribeClicked = (value: string) => console.log(value);
    const instagramNodes = get(data, 'allInstaNode');
    const instagramPosts = mapInstagramNodeListToInstagramPost(instagramNodes.edges);
    const thumbStyle = {maxWidth: 250, maxHeight: 250};

    // FIXME - Integrate with WP Metadata
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, pageData && pageData.seo ? pageData.seo : ({} as any));
    return (
        <Layout location={location} contentStyle={{marginTop: 0}}>
            <SEO title={title} meta={metadata} />
            <FeaturedItem
                text="Our goal is to make it easier and more fun to find products that you’ll love. We sort through the hundreds of products out there and selected a tight assortment of the best-of-the-best, just for YOU."
                linkTo="/collections/all"
                callToAction="I Want It All"
                title="CBD Skin care made SIMPLE for YOU."
                media={imageMainBanner}
                style={{backgroundColor: Colors.INDEX_HERO, color: Colors.WHITE}}
            />
            <NewsletterSubscribeSection
                subtitle="Sign up now and receive 10% off. Plus you'll get the latest cool news. We Promise!"
                onSubscribeClicked={onSubscribeClicked}
            />
            <SectionGallerySlider title="I Want To" items={featureCollections} />
            <SectionProductSlider title="Best Deals" products={dealsProducts} thumbStyle={thumbStyle} />
            <SectionTitle title="Top Sellers" />
            <ProductGrid products={topSellers} thumbStyle={thumbStyle} />
            <SectionTitle title="Glowygram" />
            <InstagramFeed posts={instagramPosts} />
        </Layout>
    );
};

export default StoreIndex;
